@import url("https://use.typekit.net/tlr5fsj.css");

$font-default: freight-sans-pro, serif;
$font-heading: freight-big-pro, sans-serif;



$font-size:20px;
$font-size-large:24px;  
$font-size-small:16px;
$font-size-xsmall:14px;

$font-size-button:18px;
$font-size-form:18px;

$font-size-heading-h1:36px;
$font-size-heading-h2:24px;
$font-size-heading-h3:18px;   

//Website colors
$font-color: #656565;
$red: 		 #dc042b;
$white:  	 #fff;
$black: 	 #424242;
$grey-light: #f3f3f3;
$grey:		 #d2d1cf;
$grey-dark:  #898989;

$grey-outer: #e8e8e8;
$grey-inner: #dddddb;


//Social media
$color-facebook: #3b5998;
$color-twitter: #3b5998;
$color-youtube: #bb0000;
$color-linkedin: #007bb5;
$color-instagram: #125688;
$color-whatsapp: #4dc247;

$border-radius: 5px;