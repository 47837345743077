#employees {
  .top {
    padding: 0 25px;

    p {
      font-size: 16px;
    }
  }

  h2 {
    color: #666666;
    margin-top: 30px;
  }

  .employees-tab {
    padding: 25px;

    .row {
      display: flex;
      flex-wrap: wrap;
    }

    .order-1 {
      order: 1 !important;
    }

    img {
      border: 1px solid black;
      width: 100%;
    }

    h3 {
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 1px;
      line-height: 15px;
    }

    a {
      font-size: 16px;
      color: $red;
      margin-bottom: 8px;
    }

    .content {
      a {
        display: block;
        color: #000000;
        font-size: 16px;
        text-decoration: underline;
        text-decoration-color: $red;
        margin-bottom: 14px;
      }

      .content-text {
        height: 0;
        display: none;
        font-size: 16px;
      }

      &.show {
        .content-text {
          height: auto;
          display: block;
        }
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  #employees {
    .top {
      padding: 0;

      h2 {
        font-size: 36px;
        margin-top: 70px;
      }

      p {
        font-size: 18px;

        &:last-of-type {
          margin-bottom: 60px;

        }
      }
    }

    .row {
      display: block;

      .order-1 {
        order: unset !important;
      }
    }

    .employees-tab {
      padding: 0;

      .image {
        width: 340px;
        margin-right: 30px;
        margin-bottom: 60px;
      }

      .text {
        h3 {
          font-size: 24px;
          margin-top: 0;
          margin-bottom: 10px;
        }

        a {
          margin-bottom: 30px;
        }

        p {
          font-size: 18px;
          margin-top: 30px;
        }
      }

      .content {
        #ShowMoreButton {
          display: none;
        }

        .content-text {
          height: auto;
          display: block;
        }
      }
    }
  }
}