header {
  position: relative;
  z-index: 20;
  max-height: 120px;
  height: 75px;
  display: flex;
  align-items: center;

  .logo {
    height: 100%;
    display: flex;
    width: fit-content;
    align-items: center;
  }

  .hidden-xs {
    display: none;
  }

  .content {
    .row {
      margin: 0 20px;
    }

    .logo {

      img {
        position: relative;
        z-index: 999;
        width: 100%;
        height: 100%;
        max-width: 240px;
        max-height: 53px;
      }
    }

    u li {
      &:before {
        display: none !important;
      }
    }

    nav {
      display: flex;
      height: 100%;

      #menu {
        display: flex;
        height: 100%;
        align-items: center;

        ul {
          list-style-type: none;

          li {
            list-style-type: none;

            &:before {
              display: none !important;
            }
          }
        }
      }
    }

    .contactgegevens {
      display: none;

      ul {
        margin: 0;
        padding: 15px 0 20px 0;

        li {
          color: $red !important;
          text-align: right;
          font-size: $font-size-small;

          a {
            font-weight: 700;
            color: inherit;
          }
        }
      }
    }
  }
}

// ***************************************************************** 
// SM
// ***************************************************************** 
@media (min-width: $screen-sm-min) {
  header {
    nav {
      width: 100%;
      justify-content: flex-end;
    }

    .content {
      .logo img {
        max-width: 300px;
      }
    }

    .row {
      padding: 0 40px;
    }
  }
}

// ***************************************************************** 
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {
  header {
    height: 120px;

    .no-margin {
      margin: 0;
    }

    .content {
      .row {
        margin: 0;
        display: flex;
        width: 100vw;
      }

      .container {
        padding: 0 40px;
      }
    }
  }
}

// *****************************************************************
// LG
// *****************************************************************
@media (min-width: 1200px) {
  header {
    .hidden-xs {
      display: block;
    }

    .content {


      nav {
        justify-content: center;
      }

      .contactgegevens {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        ul {
          padding-bottom: 0;

          li {
            font-size: 18px;
          }
        }
      }
    }
  }
}
