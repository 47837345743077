#contact {
  #banner {
    h2 {
      font-size: 26px;
    }
  }

  h2 {
    font-size: 26px;
  }

  #introduction {
    text-align: center;
  }

  .address {
    padding: 20px;
    border-bottom: 1px solid $grey;

    &:nth-child(odd) {
      background-color: $grey-light;
    }

    &:last-of-type {
      border: none;
    }

    h3 {
      margin-bottom: 10px;
    }

    p {
      margin: 0;
    }
  }

  #contact-content {
    padding-top: 40px;
  }

  .map-icon-label .map-icon {
    color: $red;
    font-size: 34px;
    line-height: normal;
    margin-left: -2px;
  }

  .marker-content {
    .heading {
      font-size: $font-size-small;
      margin: 0 0 5px 0;
    }

    .body {
      p {
        margin: 0 0 5px 0;
      }
    }
  }

  .maps_wrapper {
    position: relative;
    padding-bottom: 30%;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  h2 {
    color: #666666;
  }

  .item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    i {
      font-size: 32px;
      color: #DD042B;
      margin: 10px 0;
    }

    p, h3 {
      color: #646464;
      font-size: 16px;
      margin-bottom: 5px;
    }

    p {
      line-height: 28px;
    }

    a {
      color: $red;
      border: none;
      font-size: 16px;
      width: fit-content;
    }
  }

  #contact-form {
    margin-top: 40px;
    background: #F5F5F5;
    padding: 25px 15px;

    .form-group {
      .form-control {
        height: 60px;
        /* UI Properties */
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        border: 1px solid var(--unnamed-color-dddddd);
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        opacity: 1;
      }
    }

    label {
      font-size: 16px;
      font-weight: 900;
    }
  }
}


#formContact {
  .form {
    display: flex;
    flex-direction: column;
    height: 100%;

    .form-group {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;

      input {
        width: 100%;
      }
    }
  }
}

// ***************************************************************** 
// SM
// ***************************************************************** 
@media (min-width: $screen-sm-min) {
  #contact {
    #contact-content {
      padding-top: 0;
      margin-left: 40px;
    }
  }
}

// *****************************************************************
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {

  #contact {
    .address {
      p {
        &.phone,
        &.email {
          margin-top: 35px;
        }
      }
    }

    #contact-form {
      form {
        label {
          font-size: 18px !important;
        }
      }
    }
  }

  #formContact {
    max-width: 950px;
    margin: 0 auto;

    .form {
      form {
        display: flex;
        flex-direction: column;
      }

      .form-group {
        flex-direction: row;

        input, textarea {
          width: 700px;
          margin-bottom: 10px;
          height: 60px;
        }


      }
    }
  }

}


@media (min-width: $screen-md-min) {
  #contact {


    .maps_wrapper {
      padding-bottom: 20%;
    }
  }

}

@media (min-width: $screen-lg-min) {
  #contact {
    #vervolg {
      padding-top: 70px;
      padding-bottom: 60px;
    }

    h2 {
      font-size: 36px;
    }

    .form-title {
      font-size: 24px;
      margin-bottom: 20px;
    }

    .item {
      i {
        font-size: 40px;
        margin-bottom: 30px;
      }

      h3, p, a {
        font-size: 18px;
      }
    }
  }

  #contact-form {
    padding: 50px 30px 70px 30px !important;

    form {

      .form-group {
        display: flex;
        justify-content: space-between;


        .form-control {
          width: 445px !important;
        }

        textarea {
          height: 125px;
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  #contact {
    #map {
      min-height: 480px;
    }

    .button-wrapper {
      width: 445px;
    }
  }
}