#show {
  h1 {
    font-size: 36px;
  }

  .content {
    h2 {
      font-size: 26px;
      color: #666666;
    }

    p {
      font-size: 16px;
    }

    h3 {
      font-size: 20px;
      font-weight: 900;
    }

    blockquote {
      background: #F5F5F5;
      font-size: 16px;
      color: #646464;
      border-left: 5px solid #DD042B;
    }
  }

  .bottom {
    .items {
      display: grid;
      grid-template-columns: auto auto;

      .item {
        width: 100%;

        p {
          position: absolute;
          z-index: 9;
          color: #FFFFFF !important;
          width: 100%;
          top: 50%;
          left: 0;
          text-align: center;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        img {
          width: 100%;
          height: 200px;
        }
      }

    }

    .item {
      position: relative;
      display: block;
      overflow: hidden;

      .image-wrapper {
        overflow: hidden;
      }

      img {
        width: 100%;
        object-fit: cover;
        transition: .3s;
      }

      &:before {
        content: '';
        background: transparent linear-gradient(279deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%) 0% 0% no-repeat padding-box;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
      }

      &:hover {
        &:before {
          content: '';
          background: transparent linear-gradient(279deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%) 0% 0% no-repeat padding-box;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
        }


      }

      &.contactbanner {
        background: #DD042B;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;


        i {
          text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.76);
          margin-top: 8px;
        }

        p {
          position: unset;
          transform: unset;
          margin: 0 0 25px;
          text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
        }

        h2,p {
          z-index: 99;
          margin-bottom: 0px;
        }

        i, h2 {
          color: #FFFFFF !important;
        }
      }
    }

    span {
      position: absolute;
      z-index: 9;
      color: #FFFFFF;
      width: 100%;
      top: 50%;
      left: 0;
      text-align: center;
      transform: translateY(-50%);
      font-size: 16px;
    }
  }

  ul li {
    list-style-type: none;

    &:before {
      content: "\e903";
      font-family: icomoon;
      position: absolute;
      left: -3px;
    }
  }
}

@media (min-width: $screen-md-min) {
  #show {
    #vervolg {
      padding: 65px 0px 70px 0px;
    }

    h1 {
      font-size: 48px;
    }

    .content {

      h2 {
        font-size: 36px;
      }

      p {
        font-size: 18px;
      }

      blockquote {
        font-size: 18px;
      }

      h3 {
        font-size: 24px;
      }
    }

    .bottom {
      .items {
        display: flex;

        .item {
          width: 100%;
          flex: 1 1 0px;

          img {
            height: 320px;
          }

          &:hover {
            img {
              transform: scale(1.08);
            }
          }

          &.contactbanner {
            gap: 10px;
            h2 {
              font-size: 30px;
            }

            p {
              font-size: 24px !important;
            }
          }
        }

        span, p {
          font-size: 26px !important;
        }

        .row {
          display: flex;
        }
      }
    }
  }
}

@media (min-width: $screen-lg-min) {
  #show .bottom .items {
    .item {
      &.contactbanner {
        gap: 20px;
        h2 {
          font-size: 50px;
        }

        p {
          font-size: 24px;
        }
      }
    }
  }

}