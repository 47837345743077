form {
  input, select, textarea {
    font-family: $font-default;
    font-size: $font-size-form;
  }

  input[type='submit'],
  button {
    border: none;
  }
}

#form-contact-inline {
  padding: 40px 30px 20px;

  .form-group {
    float: left;
    display: inline-block;
    margin: 0 0 8px 0;
    padding: 0;
    width: 100%;

    &:last-of-type {
      margin: 0;
      width: 100%;
      text-align: center;

      .btn {
        margin-top: 15px;
        height: 65px;
        font-size: 16px;
        margin-bottom: 40px;
      }
    }

    .form-control, textarea {
      border: 1px solid var(--unnamed-color-dddddd);
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #DDDDDD;
      border-radius: 5px;
      width: 100%;
    }


    textarea {
      height: 120px !important;
    }

    .button-wrapper {
      display: flex;
      width: 100%;
    }

    textarea.form-control {
      height: 100px;
    }

    .form-control,
    .btn {
      line-height: normal;
      padding: 10px;
      height: 39px;
      font-size: $font-size-xsmall;
    }

    .btn {
      font-weight: 700;
    }

  }

  .help-block {
    font-size: $font-size-small;
  }

  h2 {
    font-size: 24px;
    color: #646464;
  }

  p {
    color: #646464;
    font-size: 16px;
    margin-bottom: 40px;
  }

  label {
    color: #646464;
    font-size: 16px;
    font-weight: 600;
  }

  p {
    max-width: 950px;
    margin: 0 auto 40px;
  }

}

// ***************************************************************** 
// SM
// ***************************************************************** 
@media (min-width: $screen-sm-min) {

  #form-contact-inline {


    h2, p {
      text-align: center;
    }

    .form-group {
      width: 49%;

      &:nth-child(odd) {
        float: right;
      }

      &:last-of-type {
        margin: 0;
      }

      textarea.form-control {
        height: 39px;
      }
    }
  }


}

// ***************************************************************** 
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {

  #form-contact-inline {
    .form-group {
      margin: 0 2% 0 0;
      width: 19%;

      &:nth-child(odd) {
        float: left;
      }

      &:last-of-type {
        margin: 0;
        width: 16%;
        text-align: left;

        .btn {
          width: 100%;
        }
      }

      textarea.form-control {
        height: 39px;
      }

      .button-wrapper {
        justify-content: flex-end;
      }
    }

    .form-group:last-of-type .btn {
      max-width: 300px;
    }
    }

}

// ***************************************************************** 
// LG
// ***************************************************************** 
@media (min-width: $screen-lg-min) {
  #form-contact-inline {
    padding: 110px 0 80px 0;
    margin: 0;

    h2 {
      margin-bottom: 30px;
      font-size: 36px;
    }

    label {
      font-size: 18px;
    }

    p {
      font-size: 18px;
    }

    .form-group:last-of-type .btn {
      margin-bottom: 0;
    }
  }
}