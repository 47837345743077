.content {
  p {
    margin: 0 0 20px 0;
    line-height: 1.5em;
  }

  ul {
    margin: 0 0 20px 0;
    padding: 0;

    li {
      position: relative;
      margin: 0;
      padding: 0 0 5px 15px;
      line-height: 1.3em;
    }
  }

  .nomargin {
    margin: 0;
  }

}

#introduction {
  padding: 20px;
  background-color: $grey-light;

  .content {
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 30px;
    }

    p {
      font-weight: 100;

      strong {
        font-weight: 300;
      }
    }
  }
}

#vervolg {
  padding: 20px;

  a {
    color: $red;
    border-bottom: 1px solid $red;

    &:hover {
      color: $black;
      border-bottom: 1px solid $black;
    }
  }
}


// ***************************************************************** 
// SM
// ***************************************************************** 
@media (min-width: $screen-sm-min) {
  #introduction {
    padding: 40px;

    h1 {
      text-align: center;
    }
  }

  #vervolg {
    padding: 40px;
  }
}


// ***************************************************************** 
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {

}

// ***************************************************************** 
// LG
// ***************************************************************** 
@media (min-width: $screen-lg-min) {

}

