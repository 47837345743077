nav {
  display: inline-block;

  ul#menu {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      display: inline-block;
      font-weight: 400;
      font-size: $font-size-small;
      white-space: nowrap;
      padding: 24px 0;

      &:hover {
        ul {
          background: #F5F5F5;
          display: block;

          .arrow-up {
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid #F5F5F5;
            position: absolute;
            top: -12px;
          }

          li {
            padding: 21px 0;
          }
        }
      }

      a {
        display: block;
        padding: 20px 5px;
        color: #000000 !important;
        font-weight: 400;
        transition: all 400ms ease-in-out;

        &:hover,
        &.active:hover,
        &.active {
          color: $black;
        }
      }

      &:last-of-type {
        a {
          //padding-right: 0px;
        }
      }

      ul {
        position: absolute;
        display: none;
        left: 0;
        top: 83px;
        margin: 0;
        padding: 2px;
        background: $white;
        min-width: 100%;

        li {
          display: block;
          font-size: $font-size-small;
          white-space: nowrap;
          text-align: left;
          padding: 1px 0 !important;

          a {
            padding: 4px 20px;
            font-size: 16px;
            &:hover {
            }

            &.active {
            }
          }
        }
      }

    }
  }
}

//
// MOBILE NAV
//
#mobilenav {
  position: absolute;
  width: 100%;
  top: 0px;
  height: 75px;
  width: 100%;
  right: 0px;
  background: $white;

  .slicknav_menu {
    background: $white;
    padding: 0;
    text-align: left;
    position: absolute;
    right: 15px;
    top: 5px;

    .slicknav_btn {
      position: relative;
      z-index: 32;
      height: 50px;
      width: 50px;
      border-radius: 0px;
      background: none;

      .slicknav_icon {
        height: 11px;
        padding: 5px 3px;

        .slicknav_icon-bar {
          width: 27px;
          height: 4px;
          margin: 4.5px 0;
          box-shadow: none;
          background-color: $grey-dark;
        }
      }
    }

    ul.slicknav_nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      background: rgba($white, .8);
      padding: 75px 0;
      margin: 0;
      overflow: scroll;
      height: 100vh;

      li {
        font-size: $font-size;
        font-family: $font-default;
        padding: 0;
        background: $grey-light;

        &:hover {
          a {
            background: none;

          }

          ul li a {
            background: none;
          }
        }

        a.active,
        &:hover a:hover {
          color: $black;
        }

        a {
          margin: 0;
          padding: 15px 0px 15px 35px;
          color: #000000;
          background-color: inherit;
          display: flex;
          align-items: center;

          .icon {
            display: none;
          }

          &.slicknav_row {
            padding: 0;
          }
        }

        &:first-of-type a {
          padding-top: 5px;
          padding-bottom: 5px;
        }

        &:last-of-type a {
          padding-bottom: 10px;
        }

        ul {
          padding: 0;
          margin: 0 0 0 15px;

          li {
            margin: 0;

            a {


            }

            ul {
              li {
                a {

                }
              }
            }
          }
        }
      }
    }

    .slicknav_open {
      .slicknav_icon-bar {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        opacity: 0;

        &:first-child {
          opacity: 1;
        }

        &:last-child {
          transform: translate(-50%, -50%) rotate(-45deg);
          opacity: 1;
        }
      }
    }
  }
}

// ***************************************************************** 
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {
  nav {
    ul#menu {
      li {
        a {
          padding: 10px 15px;
        }
      }
    }
  }

  #mobilenav {
    width: unset;
    height: unset;
  }
}

// ***************************************************************** 
// LG
// ***************************************************************** 
@media (min-width: $screen-lg-min) {
  nav {
    ul#menu {
      li a {
        font-size: 20px;
      }
    }
  }
}