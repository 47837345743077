#popup {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 0;

  .content {
    position: relative;
    z-index: 99;
    top: 50%;
    padding: 30px;
    background: #DD042B;
    max-width: 710px;
    margin: 0 auto;
    height: 50vh;
    overflow-y: scroll;

    h2 {
      color: #FFFFFF;
    }

    .text {
      background: #FFFFFF;
      padding: 20px 30px;

      p {
        color: #646464;
        font-size: 18px;
      }
    }

    a {
      position: absolute;
      top: 28px;
      right: 35px;
      color: #FFFFFF;
      font-size: 40px;
    }
  }

  &:after {
    content: '';
    background: #646464;
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    opacity: .8;
  }

  &.show {
    display: flex !important;
  }
}


@media (min-width: $screen-md-min) {
  #popup .content {
    height: 80vh;
    h2 {
      font-size: 48px;
    }

    a {
      top: 40px;
    }
  }

}