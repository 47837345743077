footer {
  border-top: 1px solid #DDDDDD;
  padding-top: 40px;

  #footer {
    padding-bottom: 20px;

    p {
      font-size: 16px !important;
    }

    .toplogo {
      max-height: 150px;
      max-width: 150px;
      padding-left: 0;
    }

    img {
      width: 100%;
      max-width: 300px;
      padding-left: 25px;
    }

    h2 {
      margin: 0 0 15px 0;
      color: $grey-dark;
    }

    h3 {
      margin: 0 0 5px 0;
      font-size: 16px;
    }

    h3, p {
      line-height: 1.5em;
    }

    p, ul li {
      font-size: 16px;
      color: $grey-dark;
    }

    a {
      color: unset !important;
      font-weight: 700;

      &.btn {
        padding: 6px 12px;
        color: $white;
        font-weight: 300;
        font-size: 16px;
      }
    }

    .inner {
      margin: 0 20px;
      padding: 5px;

      div[class*='col-'] {
        padding-bottom: 20px;
      }
    }

    .bottom-logo {
      padding: 10px 0 10px 20px;
    }

    a {
      color: $red !important;
      font-weight: 400 !important;
    }

    .bottom-logo {
      width: 200px;
      padding-left: 0;
    }

    .bottom-logo-lg {
      display: none;
    }

    .bottom {
      .content {
        padding: 0px 25px;
      }

      p, a {
        color: #646464 !important;
      }

      span {
        margin-bottom: 10px;
      }

      p {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .pipe {
        display: none;
      }
    }

    .address {
      p {
        margin-bottom: 20px;

        &.phone a {
          font-size: $font-size;
        }
      }
    }

    .employees {
      ul {
        margin: 0;
        padding: 0;

        li {
          margin: 0;
          padding: 0 0 15px 0;
          line-height: 1.3em;
        }
      }
    }

    .quicklinks {
      ul {
        margin: 0;
        padding: 0;

        li {
          position: relative;
          margin: 0;
          padding: 0 0 5px 15px;
          line-height: 1.3em;

          &:before {
            position: absolute;
            left: -5px;
            top: 6px;
            font-family: 'icomoon';
            content: "\e91c";
            color: $grey-dark;
            font-size: 10px;
          }

          a {
            color: $font-color !important;
          }
        }
      }

      img {
        margin-bottom: 5px;
      }
    }

    .socialmedia {
      text-align: center;
      padding: 20px 0 0 0;
    }

    .copyright {
      padding-top: 20px;

      p {
        position: relative;
        margin: 0;
        color: #c1c0be;

        a {
          color: inherit;
          font-weight: 300;

          &:hover {
            color: $red;
          }
        }

        .pipe {
          position: relative;
          color: inherit;
          display: inline-block;
          margin: 0 10px;

          &:before {
            position: absolute;
            left: 0;
            top: -16px;
            content: '|';
          }
        }
      }
    }
  }
}

// *****************************************************************
// SM
// ***************************************************************** 
@media (min-width: $screen-sm-min) {
  footer {
    #footer {
      .inner {
        margin: 0 40px;
      }
    }

  }
}

// ***************************************************************** 
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {
  footer {
    #footer .socialmedia {
      padding: 0 0 0 15px;
      text-align: left;
    }

    #footer {
      .bottom {
        padding: 40px 0;

        .content {
          padding: 0;
        }

        p {
          display: block;
          margin: 0;
          float: right;
        }

        span {

          margin: 0 10px;
        }

        .pipe {
          display: unset;
        }
      }
    }
  }
}

@media (min-width: $screen-lg-min) {
  footer {
    padding-top: 80px;
    #footer {
      img {
        max-width: unset;
      }



      .bottom-logo {
        display: none;
      }

      .bottom-logo-lg {
        display: block;
        padding-left: 0;
        width: 160px;
        margin-top: 30px;
      }

      .address {
        p {
          line-height: 26px;
        }
      }
    }
  }
}