.banner {
  position: relative;
  color: $white;
  overflow: hidden;
  background-color: $red;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  max-height: 320px;

  &.makeSquareBig {
    max-height: 640px;

    a {
      color: #FFFFFF;
    }
  }

  &.makeSquare {

    a {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      color: $white;
      display: block;
      cursor: pointer;

      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        transition: .1s;
      }

      //&:before {
      //  position: absolute;
      //  left: 0;
      //  top: 100%;
      //  content: '';
      //  width: 100%;
      //  height: 100%;
      //  background: rgba($black, .7);
      //  transition: all .4s ease-in-out;
      //}
      //
      //.overlay {
      //  position: absolute;
      //  left: 0;
      //  top: 0;
      //  content: '';
      //  width: 100%;
      //  height: 100%;
      //  background: transparent linear-gradient(279deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%) 0% 0% no-repeat padding-box;
      //}


    }

  }

  .big-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    height: 100%;

    .text {
      transform: translateX(10%);

      h2 {
        font-weight: 200;
        color: #FFFFFF;
        font-size: 36px;
        margin-bottom: 5px;

        span {
          font-weight: 600;
        }
      }

      ul {
        margin: 0;

        li {
          padding: 6px 0;
          font-size: 16px;
          line-height: 20px;

          i {
            margin-right: 12px;
            font-size: 13px;
          }
        }
      }
    }
  }

  .title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    min-width: 150px;

    h3, p {
      color: inherit;
      text-align: center;
      margin: 0;
      padding: 0;
      font-size: 16px;
      text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.76);
      letter-spacing: 0px;
    }

    h3 {
      font-weight: 700;
      font-family: freight-sans-pro, sans-serif !important;

      small {
        color: inherit;
        font-size: $font-size-small;
        font-weight: 100;
      }

      span {
        font-weight: 400;
      }
    }

    p {
      font-size: $font-size;
      font-weight: 400;
    }

    i {
      margin-right: 8px;
      font-size: 10px;
      vertical-align: middle;
    }
  }
}


#banner {
  display: flex;
  justify-content: center;
  height: 130px;
  background: #DD042B;
  align-items: center;

  h1 {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 0;
    padding: 0 20px;
  }
}

// ***************************************************************** 
// SM
// ***************************************************************** 
@media (min-width: $screen-sm-min) {
  .makeSquareBig {
    min-height: unset !important;
  }

  .banner {
    .big-title {
      .text {
        h2 {
          font-size: 21px;
          margin: 0 0 5px 0;
        }

        ul {
          li {
            padding: 0 !important;
            line-height: 20px;
          }
        }
      }
    }
  }
}

// ***************************************************************** 
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {
  .banner .title {
    h3, p {
      font-size: $font-size;

      small {
        font-size: $font-size;
      }
    }
  }

  #banner {
    height: 160px;

    h1 {
      font-size: 60px;
    }
  }

  .banner .big-title .text ul li {
    line-height: unset;
  }
}

@media (min-width: 1150px) {
  .banner {
    .big-title {
      .text {
        transform: translateX(20%);

        h2 {
          font-size: 48px;
          margin-bottom: 5px;
        }

        ul {
          li {
            font-size: 20px;
          }
        }
      }
    }
  }

}

// *****************************************************************
// 1600px
// *****************************************************************
@media (min-width: 1600px) {
  .banner .title {
    h3, p {
      font-size: 26px;

      small {
        font-size: $font-size-large;
      }
    }

    h3 span {
      font-size: 20px;
    }
  }

  .banner {
    .big-title {
      .text {
        transform: translateX(20%);

        h2 {
          margin-bottom: 40px;
          font-size: 48px;
          line-height: 60px;
        }

        ul {
          margin-top: 15px;
          line-height: 54px;

          li {
            font-size: 26px;
            margin-bottom: 0;
          }
        }
      }
    }

    &.makeSquare {
      a {
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}