@font-face {
	font-family: 'icomoon';
	src:  url('../../fonts/icomoon.eot?okma4h');
	src:  url('../../fonts/icomoon.eot?okma4h#iefix') format('embedded-opentype'),
	url('../../fonts/icomoon.ttf?okma4h') format('truetype'),
	url('../../fonts/icomoon.woff?okma4h') format('woff'),
	url('../../fonts/icomoon.svg?okma4h#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-minus:before {
	content: "\e920";
}
.icon-plus:before {
	content: "\e921";
}
.icon-chevron-right:before {
	content: "\e903";
}
.icon-chevron-left:before {
	content: "\e907";
}
.icon-chevron-down:before {
	content: "\e90f";
}
.icon-chevron-up:before {
	content: "\e911";
}
.icon-x:before {
	content: "\e900";
}
.icon-menu:before {
	content: "\e901";
}
.icon-check:before {
	content: "\e902";
}
.icon-Subtraction-2:before {
	content: "\e904";
}
.icon-Subtraction-1:before {
	content: "\e905";
}
.icon-fax:before {
	content: "\e906";
}
.icon-topsite:before {
	content: "\e90a";
}
.icon-star-half:before {
	content: "\e924";
}
.icon-star:before {
	content: "\e925";
}
.icon-long-arrow-left:before {
	content: "\e91b";
}
.icon-long-arrow-right:before {
	content: "\e91c";
}
.icon-twitter:before {
	content: "\e916";
}
.icon-youtube-play:before {
	content: "\e917";
}
.icon-linkedin-square:before {
	content: "\e918";
}
.icon-envelope:before {
	content: "\e915";
}
.icon-map-marker:before {
	content: "\e90c";
}
.icon-instagram:before {
	content: "\e90d";
}
.icon-facebook-official:before {
	content: "\e90e";
}
.icon-phone:before {
	content: "\e910";
}
.icon-caret-right:before {
	content: "\e912";
}
.icon-caret-left:before {
	content: "\e913";
}
.icon-caret-up:before {
	content: "\e914";
}
.icon-caret-down:before {
	content: "\e91a";
}
.icon-pinterest:before {
	content: "\ead1";
}
