#introduction {
  padding: 40px 20px 0 !important;
  background-repeat: no-repeat;
  background-position: right bottom;
  background: none !important;

  .content {
    max-width: 1450px;
    margin: 0 auto;
    //gap: 45px;

    h1 {
      font-size: 26px;
      color: #666666 !important;
    }

    p {
      font-size: 16px;
    }

    a {
      margin: 20px 0;
    }

    img {
      max-width: 170px;
      margin: 0 auto;
    }
  }
}


#homepage-services {
  padding: 40px 30px 20px 30px;
  background: #F5F5F5;

  h2 {
    text-align: left;
    color: #666666;
  }

  .items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0 25px;

    .item {
      display: flex;
      width: 100%;

      span {
        font-weight: 600;
        color: $red;
        font-size: 16px;
      }

      p {
        font-size: 16px;
      }

      i {
        color: #E8E8E8;
        font-size: 70px;
        margin-right: 35px;
      }
    }
  }

  .employees {
    position: relative;

    figure {
      display: flex;
      flex-direction: row-reverse;
      background: #FFFFFF;
      position: relative;
      padding: 20px 8px 0 20px;
      justify-content: flex-end;
      border-radius: 5px;
      min-height: 236px;
      margin: 20px 0;

      .image-wrapper {
        display: flex;
        align-items: flex-end;

        img {
          object-fit: contain;
          width: 100%;
          right: 22px;
          bottom: 0;
          max-height: 356px
        }
      }

      p {
        margin: 0 0 20px 0;
        font-size: 13px;
        text-align: center;
        color: #646464;
        line-height: 18px !important;
        width: 182px;
      }

      figcaption {
        order: 1;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
      }
    }

    .swipes {
      position: absolute;
      height: 100%;
      display: flex;
      width: 100%;
      top: 0;
      align-items: center;

      i {
        position: absolute;
        color: #C3C3C3;
        font-size: 30px;
      }

      .left {
        left: 0px;
      }

      .right {
        right: 0px;
      }
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;

    a {
      margin: 20px 0;
    }
  }
}

//#homepage-employees {
//  display: none;
//
//  article {
//    background-repeat: no-repeat;
//    background-position: right bottom;
//    background-size: auto 85%;
//    min-height: 275px;
//    transition: all .4s ease-in-out;
//
//    p {
//      font-size: $font-size;
//      padding: 20px;
//      width: 60%;
//      text-align: center;
//      max-width: 400px;
//
//      .red {
//        display: block;
//        margin-top: 10px;
//      }
//    }
//  }
//
//  div[class*='col-']:nth-child(1) article {
//    background-position: right 15px bottom;
//    background-color: #FFFFFF;
//  }
//
//  div[class*='col-']:nth-child(2) article {
//    background-position: left 15px bottom;
//    background-color: #E8E8E8;
//
//    p {
//      float: right;
//    }
//  }
//}

#homepage-information {
  padding: 20px;

  .apply {
    padding: 20px;
    margin-bottom: 20px;
    background-color: $grey-dark;

    h2 {
      margin: 0;
    }
  }

  .employees {
    margin-bottom: 20px;
    background-color: #f3f3f3;

    .row {
      padding: 10px 20px;
      border-bottom: 1px solid #dedede;
      font-size: $font-size-small;

      &:last-of-type {
        border: none;
      }

      a:hover {
        color: $black;
      }
    }

    .phone {
      text-align: left;
    }

    figcaption {
      order: 1;
    }
  }

  .specialists {
    margin-bottom: 20px;
  }

}

@media (min-width: 500px) {
  #homepage-services .employees {
    figure {
      justify-content: space-between;

      figcaption {
        width: 60%;

        p {
          width: 100%;
          font-size: 16px;

        }
      }
    }
  }
}


// *****************************************************************
// SM
// *****************************************************************
@media (min-width: $screen-sm-min) {
  #introduction {
    .text {
      h1 {
        text-align: left;
      }

      .info a {
        width: fit-content;
      }
    }
  }

  #homepage-services {
    padding: 40px 20px;

    h2 {
      text-align: center;
    }

    .items {
      gap: 15px;
      flex-direction: row;

      .item {
        justify-content: center;
        align-items: center;

        i {
          margin-right: 22px;
        }
      }
    }

    .employees {
      figure {
        margin: 20px auto;
        min-height: 380px;

        img {
          width: 140px;
        }

        p {
          line-height: 27px !important;
        }
      }

      figcaption {
        margin-top: 50px;
        padding: 0 25px;
      }

      .swipes i {
        font-size: 50px;
      }
    }

    .faq {
      padding: 40px;
      margin-right: 40px;
    }
  }


  #homepage-information {
    padding: 40px 40px 20px 40px;

    .apply {
      padding: 40px;
    }

    .employees {
      .phone {
        text-align: right;
      }
    }

    .specialists {
      padding: 0 0 0 40px;
    }
  }
}


// *****************************************************************
// MD
// *****************************************************************
@media (min-width: $screen-md-min) {
  #introduction {
    .content {
      max-width: 950px;

      h1 {
        text-align: center;
      }
    }
  }

  #homepage-services {
    h2 {
      text-align: center;
      font-size: 36px;
    }

    .items {
      flex-direction: row;
      margin: 45px auto;
      max-width: 1450px;

      .item {
        text-align: center;
        position: relative;
        display: flex;
        justify-content: center;

        .text {
          z-index: 9;
          position: relative;
        }

        i {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          margin-right: 35px;
        }

        span {
          font-size: 20px;
        }

        p {
          font-size: 18px;
          max-width: 355px;
        }
      }
    }

    //#homepage-employees {
    //  display: block;
    //}

    .button-wrapper {
      margin: 30px 0 0 0;

      a {
        max-width: 325px;
      }
    }

    .employees {
      max-width: 1446px;
      margin: 0 auto;

      .swipes {
        .right, .left {
          font-size: 50px;
        }

      }

      figure figcaption {
        order: unset !important;

        p {
          font-size: 18px;
        }
      }

      .grey {
        background: #E8E8E8 !important;
      }
    }
  }


  //#homepage-employees {
  //  article {
  //    background-size: auto 90%;
  //
  //    p {
  //      padding: 40px;
  //    }
  //  }
  //}

}

// *****************************************************************
// LG
// *****************************************************************
@media (min-width: $screen-lg-min) {
  #introduction {
    .content {
      position: relative;
      margin: 60px auto 80px auto;
      max-width: 1450px !important;

      .text {
        display: flex;

        h1 {
          font-size: 36px;
          width: 115%;
          text-align: left;
        }

        p {
          font-size: 18px;
          line-height: 27px;
        }
      }

      img {
        margin: 0;
        position: absolute;
        bottom: -60px;
      }

      a {
        display: block;
        margin-top: 38px;
        width: 140px;
      }

      img {
        bottom: -80px;
        max-width: 200px;
      }
    }
  }

  #homepage-services {
    padding: 70px 0;


    .employees {
      .swipes {
        .right {
          right: -55px;
        }

        .left {
          left: -55px;
        }
      }

      figure {
        height: 400px;
      }
    }

    .items .item i {
      font-size: 95px;
    }
  }
  //#homepage-employees {
  //  max-width: 1450px;
  //  margin: 0 auto;
  //
  //  article {
  //    min-height: 400px;
  //
  //
  //    p {
  //      font-size: 18px;
  //      width: 67%;
  //      max-width: unset;
  //      padding: 70px 50px 50px 50px !important;
  //
  //      span {
  //        font-weight: 900;
  //
  //        &.red {
  //          margin-top: 20px;
  //        }
  //      }
  //    }
  //  }
  //}
}

