html {
    overflow-x: hidden;
}

*{
    text-decoration:none;
    outline: none;
}

p,a {
    line-height: 27px !important;
}
p, li {
    color: #000000 !important;
}

#show li {
    line-height: 27px;
}


#show h2 {
    color: #DD042B !important;
    margin-bottom: 0px;
}

a {
    &:focus {
        outline: none !important;
    }
}

body {  
    font-family: $font-default;
    font-size: $font-size;
    font-weight:400;
    color:$font-color;
    line-height: 1.5em; 
    overflow-x:hidden;

    .container{
        padding:0;
        .row{
            margin:0;
            div[class*='col-']{
                padding:0;
            }
        }
    }
}

p{
    font-family: inherit;
    font-size:inherit;
    line-height:1.4em;
    margin:0 0 15px 0;
    padding:0;
}

ul,ol{
    list-style: none;
    margin:0 0 15px 0;
    padding:0;
    li{
        
    }
}

blockquote{
    margin:30px 0;
    padding:30px; 
    font-family: $font-heading;
    font-size:$font-size-heading-h2;
    color:$white;
    text-align: center;
    line-height:1.5em; 
    border:none;
}

a{
    text-decoration: none;
    transition:all 0.5s ease-in-out 0s;
    &:hover, &:focus { 
        text-decoration: none; 
    }
}

ul li {
    font-size: 16px;
}

h1,h2,h3,h4,h5,h6{ 
    font-family: $font-heading; 
    line-height: 1.4em; 
    margin:0 0 15px 0;
    padding:0; 
}
    h1{
        font-size:$font-size-large;
        font-weight:700;
        color:$red;
    }
    h2{
        font-size:$font-size-large; 
        font-weight:700;
        color:$red;
    }
    h3,h4,h5,h6{ 
        font-size:$font-size;
        font-weight:700;
    }

input,select,button{
    font-family: inherit;
    font-size:inherit;
    line-height: normal; 
    margin:0;
    padding:0;
}
.thin{
    font-weight: 100;
} 
     .red{
        color:$red;
     }
     .white{
        color:$white;
     }
     .grey{
        color:$font-color;
     }

.clearfix{
    clear: left; 
}


.btn{
    background-color:$red;
    color:$white;
    transition:all .4s ease-in-out;
    font-size:18px !important;
    width: 100%;
    border-radius: 5px;
    padding: 15px;
    min-width: 175px;
    display: flex;
    align-items: center;
    font-weight: 700 !important;
    justify-content: center;

    &:hover{
        color:$white;
        background-color: #C60024;
    }

    &.arrow {
        position: relative;
        &:after {
            content: "\e91c";
            margin-left: 10px;
            font-family: icomoon;
            font-size: 11px;
        }
    }
}
 


.facebook{
    background:$color-facebook;
}
.twitter{
    background: $color-twitter;
}
.youtube{
    background: $color-youtube;
}
.linkedin{
    background: $color-linkedin;
}
.instagram{
    background: $color-instagram;
}


.to-top {
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 999; 
    width: 50px;
    height: 50px; 
    display: none;
    cursor: pointer;
    i{
        position:absolute;
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
        color: $red; 
        transition:all .4s ease-in-out;
    }
    &:hover{
        i{
            color:$grey-dark;
        }
    }
}



// ***************************************************************** 
// SM
// ***************************************************************** 
@media (min-width: $screen-sm-min) {
    h1{
        font-size:$font-size-heading-h1;
        font-weight:700;
    }
    h2{
        font-size:$font-size-heading-h2; 
        font-weight:700;
    }
    h3,h4,h5,h6{ 
        font-size:$font-size-heading-h3;
        font-weight:700;
    }
}


// ***************************************************************** 
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {
    ul li {
        font-size: 18px;
    }
}

// ***************************************************************** 
// LG
// ***************************************************************** 
@media (min-width: $screen-lg-min) {
    .btn {
        width: fit-content !important;
        padding: 16px 22px;
        height: 65px !important;
    }

}

